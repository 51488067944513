import { forwardRef, useState } from "react";
import { tcx } from "src/utils/tailwind-classes";

export type ImgLoadingSkeletonProps = {
  alt: string;
  className?: string;
  height?: number;
  src: string;
  width?: number;
};

export const ImgWithLoadingSkeleton = forwardRef<
  HTMLImageElement,
  ImgLoadingSkeletonProps
>(({ alt, className, height, src, width }, ref) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const onLoadHandler = () => setIsLoaded(true);
  const baseClassName = `${
    isLoaded ? "opacity-100" : "opacity-0"
  } transition-opacity duration-1000`;

  const containerClassName = tcx({
    [`h-[${height}px]`]: !isLoaded && height,
    [`w-[${width}px]`]: !isLoaded && width,
  });

  return (
    <div className={containerClassName}>
      <img
        className={tcx(baseClassName, className)}
        src={src}
        alt={alt}
        onLoad={() => onLoadHandler()}
        ref={ref}
      />
    </div>
  );
});
ImgWithLoadingSkeleton.displayName = "ImgWithLoadingSkeleton";
